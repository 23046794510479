import React from 'react';
import {
  ComponentPreview,
  DontDo,
  DontDoItem,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  PlatformTableRow,
  Section,
  SectionSubhead,
} from 'docComponents';
import { Button, Toast } from 'hudl-uniform-ui-components';
import toastPreviewData from '../../../../data/previews/toast.yml';
import pageHeroData from '../../../../data/pages/components.yml';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isToastOpen: false,
    };
  }

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Toast"
        designCode
        subnav="components">
        <PageHero
          heroData={pageHeroData}
          tierTwo="Notifications"
          tierThree="Toast"
        />
        <Paragraph>
          If the message would be more effective <em>before</em> the task,
          consider a{' '}
          <Link href="/components/notifications/notice" isDesignCodeLink>
            notice
          </Link>{' '}
          or{' '}
          <Link href="/components/notifications/note" isDesignCodeLink>
            note
          </Link>{' '}
          instead.
        </Paragraph>
        <PageNavigation>
          <PageNavigationLink>Type</PageNavigationLink>
          <PageNavigationLink>States</PageNavigationLink>
          <PageNavigationLink>Usage</PageNavigationLink>
          <PageNavigationLink>Microcopy</PageNavigationLink>
          <PageNavigationLink>Platform</PageNavigationLink>
        </PageNavigation>

        <Section title="Type">
          <ComponentPreview
            name="ToastType"
            layout="row"
            previewData={toastPreviewData.types}>
            <Button
              type="primary"
              text="Trigger Toast"
              onClick={() => this.setState({ isToastOpen: true })}
              ignoreThisComponent
            />
            {this.state.isToastOpen && (
              <Toast
                message="A toast, ya know?"
                onDismiss={() => this.setState({ isToastOpen: false })}
              />
            )}
          </ComponentPreview>
        </Section>

        <Section title="States">
          <SectionSubhead>Web</SectionSubhead>
          <Paragraph>
            All toasts appear at 100% and immediately fade to 90% for a
            four-second hold before automatically dismissing with a three-second
            fadeout.
          </Paragraph>
          <Paragraph>
            The auto-dismiss timeline gives users a full seven seconds to hover
            and hold the toast at 100%.
          </Paragraph>
          <Paragraph>
            <strong>Critical toasts do not auto-dismiss</strong>. We need the
            user to acknowledge the problem being communicated.
          </Paragraph>

          <SectionSubhead>Apple</SectionSubhead>
          <Paragraph>
            Toasts will auto-dismiss after three seconds if you choose to make
            it timed. If you don’t, the toast must be deliberately dismissed
            with a single tap or swipe.
          </Paragraph>
        </Section>

        <Section title="Usage">
          <SectionSubhead>Action Buttons</SectionSubhead>
          <Paragraph>
            A dismiss is included on all toasts, but you can also include one
            additional action styled as a small subtle button.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="add an irrelevant action just to have one."
              img="toast-action-dont"
            />
            <DontDoItem
              type="do"
              text="make the action a clear next step relevant to the content."
              img="toast-action-do"
            />
          </DontDo>

          <Paragraph>
            <strong>All critical toasts require an action</strong>, usually
            Learn More.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="abandon the user to solve the problem themselves."
              img="toast-criticalaction-dont"
            />
            <DontDoItem
              type="do"
              text="help them understand what went wrong and offer a solution."
              img="toast-criticalaction-do"
            />
          </DontDo>

          <SectionSubhead>Position</SectionSubhead>
          <Paragraph>
            Position is handled for you, regardless of platform. On web, the
            toast will appear in the bottom right corner. On Apple, it’s
            positioned along the top of the screen.
          </Paragraph>
          <Paragraph>
            For the time being, stick to displaying one toast at a time.
          </Paragraph>
        </Section>

        <Section title="Microcopy">
          <SectionSubhead>Case & Punctuation</SectionSubhead>
          <Paragraph>
            Toasts follow the{' '}
            <Link href="/words/content-elements/labels-non-label-text#Non-Label%20UI%20Text">
              non-label text
            </Link>{' '}
            guidelines. They use sentence case with appropriate punctuation.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="yell at the user with caps lock or crazy punctuation."
              img="toast-casepuctuation-dont"
            />
            <DontDoItem
              type="do"
              text="use complete sentences followed by a period."
              img="toast-casepuctuation-do"
            />
          </DontDo>

          <SectionSubhead>Content Length</SectionSubhead>
          <Paragraph>
            Keep the content readable at a glance—no more than 60 characters
            long. Put keywords at the front.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="overwhelm the user with too many details."
              img="toast-contentlength-dont"
            />
            <DontDoItem
              type="do"
              text="keep things brief with only the most important info."
              img="toast-contentlength-do"
            />
          </DontDo>

          <SectionSubhead>Titles</SectionSubhead>
          <Paragraph>
            Given a toast’s size and the scope of information provided, it
            should never have a title.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="take up valuable real estate with unnecessary copy."
              img="toast-titles-dont"
            />
            <DontDoItem
              type="do"
              text="load the content with keywords to make scanning easy."
              img="toast-titles-do"
            />
          </DontDo>

          <SectionSubhead>Action Buttons</SectionSubhead>
          <Paragraph>
            Try limiting the action to a single verb, ideally one of the
            following five: <strong>Undo</strong>, <strong>Retry</strong>,{' '}
            <strong>View</strong>, <strong>Share</strong> or{' '}
            <strong>Learn More</strong>.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="make button labels complete sentences."
              img="toast-actionbuttons-dont"
            />
            <DontDoItem
              type="do"
              text="keep things simple with a single verb."
              img="toast-actionbuttons-do"
            />
          </DontDo>
        </Section>

        <Section title="Platform">
          <PlatformTable>
            <PlatformTableRow platform="web" />
            <PlatformTableRow platform="apple" />
            <PlatformTableRow platform="android" />
          </PlatformTable>
        </Section>
      </PageWithSubNav>
    );
  }
}
